// src/components/admin/admin-review-appt-req.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"
import { AdminEditRequest, AdminViewRequest} from "../../components/admin"
import { ProfilePopupOverlay, GeneralUploadStatus } from '../../components';



const AdminReviewApptRequests = (profileEmail) => {
    const {userProfile, setUser } = useContext(UserContext)
    const [apiRes, setApiRes] = useState([])
    const [apiUpdate, setApiUpdate] = useState([])
    const [orgList, setOrgList] = useState ({})
    const [initialFirmList, setInitialFirmList] = useState([])
    const [searchStatus, setSearchStatus] = useState('Open')
    const [selectedFirmId, setSelectedFirmId] = useState()
    const [showResults, setShowResults] = useState(false)
    const [viewRequest, setViewRequest] = useState(false)
    const [showOpen, setShowOpen] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [showNotaryPopup, setShowNotaryPopup] = useState(false)
    const [notaryEmail, setNotaryEmail] = useState('')
    const api = RestHelper()
   
    useEffect (() => {
      getFirmList();
    }, [apiUpdate, setApiUpdate]);
    
    useEffect(() => {
        const idArray = ['firm']
        const firmArray = initialFirmList
        const firmDict = {}
        firmArray.forEach(value => {
            firmDict[value['org_name']] = value['org_id']
        })
        idArray.forEach(value => {
            const firmVar = document.getElementById(value)
            firmArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2['org_name']
                firmVar.appendChild(option)
                setOrgList({
                    ...orgList,
                    firmDict
                })
            })
        })
    },[initialFirmList,setInitialFirmList]);

    const changeView = () => {
      setViewRequest(false)
      setShowResults(true)
      getApi()
    }
    const getRequest = (data) => {
      setRequestId(data.RequestId)
      setViewRequest(true);
      setShowResults(false)
    }

    // if Check Pending Requests is selected, we'll query for pending requests
  
    const getApi = () => {
      const options = {}
      const passedData = {'firmId' : selectedFirmId, 'status' : searchStatus}
      api
          .postCreate('/get-admin-pending-appts', options, passedData)
          .then(res => setResponse(res))
          .catch(err => console.log(err))
      setShowResults(true)
      }

    const getFirmList = () => {
     const options = {}
     const passedData = {'activeUser' : userProfile.profileEmail}
     api
        .postCreate('/get-firm-list', options, passedData)
        .then(res => setInitialFirm(res))
        .catch(err => console.log(err))
    }

    const setResponse = (res) => {
      setApiRes(res)
      if (res[0]['Status'] == 'Open'){
        setShowOpen(true)
      }
      else {
        setShowOpen(false)
      }
    }

    const dateFormat = (inputDate) => {
      const dateArray = inputDate.split("-")
      const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
      return date
    }
    const setInitialFirm = (res) => {
      var firmArray = [{'org_name': 'All', 'org_id': 1000}].concat(res['firms'])
      setInitialFirmList(firmArray)
      setSelectedFirmId(1000)
    }

      const updateNotary = (props) => {
        const options = {}
        api
            .postCreate('/update-notary-registration', options, props)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
        }  
    const selectFirm = (firm) => {
        const firmInfo = document.getElementById(firm.target.id)
        setSelectedFirmId(orgList['firmDict'][firmInfo.value])
    }

    const selectStatus = (status) => {
        const searchPref = document.getElementById(status.target.id)
        setSearchStatus(searchPref.value)
    }

    function clearModal() {
      setShowNotaryPopup(false)
    }

    function getProfile(e) {
      setNotaryEmail(e)
      setShowNotaryPopup(true)
    }
  
    function confirmAction(e) {
      //do nothing here
    }

    const onClickHandler = () => {
      getApi()
    }
    const onSubmitHandler = (action) => {
        const checklist = []
        const approval = {'action': action}
        apiRes.forEach(item =>{
            const checkValue = document.getElementById(item.email)
            if (checkValue.checked){
                checklist.push({'email' : item.email})
            }
        })
        if (checklist.length > 0) {
            updateNotary([approval,checklist])
        }
    }
 
    return(
     <div className="container flex-grow-1">
          <div className="mt-5">
            <div className='row'>
             <div className="col mb-3 mt-2">
               <label for="firm" className='form-label col'>Firm</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="firm" onChange={(e)=>selectFirm(e)}></select>
             </div>
             <div className="col mb-3 mt-2">
               <label for="status" className='form-label col'>Status</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="status" onChange={(e)=>selectStatus(e)}>
                  <option value='Open'>Open</option>
                    <option value='Pending'>Pending</option>
                    <option value='Scheduled'>Scheduled</option>
                    <option value='Paused'>On Hold</option>
                    <option value='Completed'>Completed</option>
                    <option value='Canceled'>Canceled</option>
                  </select>
             </div>
            </div>
            <div className='row'>
              <button type='submit' className='btn rounded btn-md btn-secondary mx-3 mb-3' onClick={()=>onClickHandler()}>Search</button>
            </div>
            {showResults &&
            (<div>
            <h6 className="muted">Here are the results of your appointment search:</h6>
            <div className="container-fluid rounded">
              <div className="table-responsive">
                <table className="table table-hover">
                <tbody>
                  <tr scope="row">
                  <th scope="col">Appt Name</th>
                  <th scope="col"></th>
                  <th scope="col"> Files Uploaded</th>
                  <th scope="col">Status</th>
                  <th scope="col">Signing Agent</th>
                  <th scope="col">Firm Name</th>
                  <th scope="col">Appt Date</th>
                  </tr>
                  {apiRes.map((items =>
                      <tr scope="row" key={items.RequestId}>
                          <td><button id="button1" className='btn btn-link' type='button' onClick={() => getRequest(items)}>{items.Appt_Name}</button></td>
                          <td><GeneralUploadStatus action='messages' confirm = {items.unreadMessages}/></td>
                          <td><GeneralUploadStatus action='write' confirm = {items.filesAttached}/></td>
                          <td>{items.Status}</td>
                          <td><button id="button2" className='btn btn-link' type='button' onClick={() => getProfile(items)}>{items.NotaryName}</button></td>
                          <td>{items.Firm_Name}</td>
                          <td>{dateFormat(items.Appt_Date)}</td>
                      </tr>
                  ))}
                </tbody></table>
              </div>
            </div>
          </div>)}
          {viewRequest &&
           (<div className='container mb-3 border rounded bg-light'>
            <div className='row rounded bg-white'>
              <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
              </button>
            </div>
            <Fragment>
              {showOpen
              ? <AdminEditRequest requestId={requestId}/>
              : <AdminViewRequest requestId={requestId} searchStatus={searchStatus}/>
            }
            
            </Fragment>
          </div>)}
          <Fragment>
            {showNotaryPopup && <ProfilePopupOverlay clearModal={clearModal} confirmAction={confirmAction} email={notaryEmail.Notary} />}
          </Fragment>
        </div>
    </div>
    );
}
export default AdminReviewApptRequests;