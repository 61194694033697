import React, { useState, useEffect } from 'react';
import { RestHelper } from '../../helper/restHelper'

// Helper function to convert a string to proper case
const toProperCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

// Helper function to format the number as currency
const formatCurrency = (value) => {
  // Remove non-numeric characters except the period
  let sanitizedValue = value.replace(/[^0-9.]/g, '');

  const parts = sanitizedValue.split('.');
  if (parts.length > 2) {
    sanitizedValue = parts[0] + '.' + parts[1]; // Ensure only one period
  }

  // Add the dollar sign at the start
  return `$${sanitizedValue}`;
  };

const AdminAddFirm = ( {existingFirms,closePortal } ) => {
  const defaultValues = {
    action: 'add',
    org_name: '',
    org_address: '',
    org_city: '',
    org_contact_email: '',
    org_contact_name: '',
    org_fee: '',
    org_state: '',
    org_zip: '',
    sub_org: 3000
  }
  const [formData, setFormData] = useState(defaultValues);

  const [apiRes, setApiRes] = useState([])
  const api = RestHelper()
  const [errors, setErrors] = useState({
    org_zip: '',
    org_name: ''
  });

  // Validate ZIP code (must be 5 digits)
  const validateZipCode = (zip) => {
    const zipRegex = /^\d{5}$/; // Ensures ZIP code is exactly 5 digits
    return zipRegex.test(zip);
  };

    // Check if the organization name already exists in the passed list of firm objects
    const isOrgNameDuplicate = (orgname) => {
        const properName = toProperCase(orgname);
        // Assuming each firm in the list has a 'name' field
        return existingFirms.some(firm => toProperCase(firm.org_name) === properName);
      };

    const getApi = () => {
        const options = {}
        const passedData = formData
        api
          .postCreate('/manage-firms-admin', options, passedData)
          .then(res => setApiRes(res))
          .catch(err => console.log(err))
        setFormData(defaultValues)
        closePortal()
      }
  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Enforce lowercase for email and proper case for names
    if (name === 'org_contact_email') {
      updatedValue = value.toLowerCase();
    } else if (name === 'org_name' || name === 'org_contact_name') {
      updatedValue = toProperCase(value);
    }

  // Format fee as currency
  if (name === 'org_fee') {
    // Only allow digits and period, and format as currency
    updatedValue = formatCurrency(value);
  }

    // Validate ZIP code
    if (name === 'org_zip') {
      if (!validateZipCode(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          org_zip: 'Zip code must be a 5-digit number'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          org_zip: ''
        }));
      }
    }
            // Check for duplicate organization name
            if (name === 'org_name') {
                if (isOrgNameDuplicate(value)) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        org_name: 'This organization name is already taken'
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        org_name: ''
                    }));
                }
            }

    setFormData({
      ...formData,
      [name]: updatedValue
    });
  };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Final ZIP code validation before submission
        if (!validateZipCode(formData.org_zip)) {
            alert('Please enter a valid 5-digit ZIP code.');
            return;
        }
        getApi()
        console.log('Form data submitted:', formData);
    };

    // Effect to format the fee as currency after every change
    useEffect(() => {
        if (formData.org_fee) {
          setFormData((prevData) => ({
            ...prevData,
            org_fee: formatCurrency(prevData.org_fee)
          }));
        }
      }, [formData.org_fee]);  

  return (
    <form onSubmit={handleSubmit} className="needs-validation">
      <div className="form-group">
        <label>Organization Name:</label>
        <input
          type="text"
          className="form-control"
          name="org_name"
          value={formData.org_name}
          onChange={handleChange}
          required
        />
        {errors.org_name && <small className="text-danger">{errors.org_name}</small>}
      </div>

      <div className="form-group">
        <label>Organization Address:</label>
        <input
          type="text"
          className="form-control"
          name="org_address"
          value={formData.org_address}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>City:</label>
        <input
          type="text"
          className="form-control"
          name="org_city"
          value={formData.org_city}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>Contact Email:</label>
        <input
          type="email"
          className="form-control"
          name="org_contact_email"
          value={formData.org_contact_email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>Contact Name:</label>
        <input
          type="text"
          className="form-control"
          name="org_contact_name"
          value={formData.org_contact_name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>Fee:</label>
        <input
          type="text"
          className="form-control"
          name="org_fee"
          value={formData.org_fee}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>State:</label>
        <select
          className="form-control"
          name="org_state"
          value={formData.org_state}
          onChange={handleChange}
          required
        >
          <option value="">Select a state</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>

      <div className="form-group">
        <label>Zip Code:</label>
        <input
          type="text"
          className="form-control"
          name="org_zip"
          value={formData.org_zip}
          onChange={handleChange}
          required
        />
        {errors.org_zip && <small className="text-danger">{errors.org_zip}</small>}
        </div>
    <div className="form-group">
        <label>Managing Organization:</label>
        <select
          className="form-control"
          name="sub_org"
          value={formData.sub_org}
          onChange={handleChange}
          required
        >
          <option value={3000}>Pinpoint Field Serivces</option>
          <option value={3001}>Victor Sosa</option>
          </select>
    </div>

      <button type="submit" className = "btn btn-primary btn-sm mx-3 mb-3">Submit</button>
    </form>
  );
};

export default AdminAddFirm;
